import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, tap } from 'rxjs';
import { CountryCodeModel } from '../models/country-code.model';

@Injectable({
  providedIn: 'root',
})
export class CountryCodeService {
  private readonly jsonPath = 'assets/config/country-codes.json';

  private dataSubject = new BehaviorSubject<Array<CountryCodeModel>>(null);
  public dataSub$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient) {}

  public get data(): Array<CountryCodeModel> {
    return this.dataSubject.value;
  }

  public load(): Observable<Array<CountryCodeModel>> {
    return this.http
      .get<Array<CountryCodeModel>>(this.jsonPath)
      .pipe(tap((data) => this.dataSubject.next(data)));
  }

  public async loadAsync(): Promise<Array<CountryCodeModel>> {
    try {
      return await lastValueFrom(this.load());
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
