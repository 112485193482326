import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  ValidationStatus,
} from '../../shared/services/AuthService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { sleep } from 'src/app/shared/time.functions';
import { Subscription } from 'rxjs';
import { DEFAULT_PAGE, PasswordMaxLength } from 'src/app/shared/staticObjects';

export enum AuthPageMode {
  Login = 1,
  ForgotPassword = 2,
  ResetPassword = 3,
}

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  readonly PasswordMaxLength = PasswordMaxLength;

  loader: boolean;
  showLoginPage: boolean;
  showError: boolean;
  showErrorResetPassword: boolean;
  authPageMode: AuthPageMode;
  showCompletePassword = false;
  passwordVisible: boolean;

  isPasswordLawerCase: boolean;
  isPasswordUpperCase: boolean;
  isPasswordHasNumber: boolean;
  isPasswordHasSpecialCharacter: boolean;
  isPasswordUpTo8Characters: boolean;
  isMatchPasswords: boolean;

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [
    Validators.required,
    Validators.maxLength(PasswordMaxLength),
  ]);
  loginForm: FormGroup;

  emailF = new FormControl('', [Validators.required, Validators.email]);
  forgotPasswordForm: FormGroup;

  codeR = new FormControl('', []);
  newPasswordR = new FormControl('', [
    Validators.required,
    Validators.maxLength(PasswordMaxLength),
  ]);
  againPasswordR = new FormControl('', [
    Validators.required,
    Validators.maxLength(PasswordMaxLength),
  ]);
  passwordResetForm: FormGroup;

  queryParamsSub$: Subscription;

  constructor(
    private authService: AuthService,
    private builder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public helperService: HelperService
  ) {
    this.loader =
      this.showError =
      this.isPasswordHasNumber =
      this.isPasswordHasSpecialCharacter =
      this.isPasswordLawerCase =
      this.isPasswordUpperCase =
      this.isPasswordUpTo8Characters =
      this.isMatchPasswords =
      this.showErrorResetPassword =
        false;
    this.passwordVisible = true;
    this.showLoginPage = false;
    this.createForm();
    this.changeMode(AuthPageMode.Login);
  }

  ngOnDestroy(): void {
    this.queryParamsSub$?.unsubscribe();
  }

  createForm(): void {
    this.loginForm = this.builder.group({
      email: this.email,
      password: this.password,
    });
    this.forgotPasswordForm = this.builder.group({
      emailF: this.emailF,
    });
    this.passwordResetForm = this.builder.group({
      codeR: this.codeR,
      newPasswordR: this.newPasswordR,
      againPasswordR: this.againPasswordR,
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      if (this.authService.validationResult === ValidationStatus.Valid) {
        this.router.navigate([DEFAULT_PAGE]);
      } else {
        this.showLoginPage = true;

        this.queryParamsSub$ = this.route.queryParams.subscribe((param) => {
          let email: string = param['email'] ?? '',
            code: string = param['code'] ?? '';

          if (email.indexOf(' ') > -1) {
            email = email.replace(/ /g, '+');
          }

          if (email?.length && code?.length) {
            this.emailF.setValue(email);
            this.codeR.setValue(code);
            this.authPageMode = AuthPageMode.ResetPassword;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onLogin(): Promise<void> {
    this.loader = true;

    let userName = this.loginForm.value.email; //'hamed.zadeh@fullswinggolf.com';
    let password = this.loginForm.value.password; //'Aa2551475!';

    try {
      let res = await this.authService.signIn(userName, password);
      this.loader = false;

      if (res === 1 || res === -1) {
        const isAuth = await this.authService.isAuthenticatedCognitoGroups();

        if (!isAuth) {
          this.helperService.notify('ERROR', 'User is not techadmin', 1500);
          await sleep(1500);
          await this.authService.signOut();
          return;
        }
      }

      if (res === 1) {
        let userId = await this.authService.getUserId();

        if (!userId?.length) {
          this.helperService.notify('ERROR', 'Login failed', 3000);
          await this.authService.unautorized();
          return;
        }

        console.log('auth: ', res);
        this.authService.validationResult = ValidationStatus.Valid;
        window.location.href = `/${DEFAULT_PAGE}`;
      } else if (res === -2) {
        this.showError = true;
        this.helperService.notify('ERROR', 'Login failed', 3000);
        await this.authService.unautorized();
        console.log('auth failed: ', res);
      } else if (res === -1) {
        this.changeMode(AuthPageMode.ResetPassword);
        this.showCompletePassword = true;
      }
    } catch (err) {
      console.log('Current user info failed to fetch', err);
      this.helperService.notify('ERROR', 'Server failed', 3000);

      await this.authService.unautorized();
    } finally {
      this.loader = false;
    }
  }

  async onForgotPassword(): Promise<void> {
    try {
      this.loader = true;

      const isAuth = await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuth) {
        this.helperService.notify('ERROR', 'User is not techadmin', 1500);
        await sleep(1500);
        await this.authService.signOut();
        return;
      }

      let userName = this.forgotPasswordForm.value.emailF;

      await this.authService.forgotPassword(userName);

      this.changeMode(AuthPageMode.ResetPassword);
    } catch (error) {
      await this.authService.unautorized();
    } finally {
      this.loader = false;
    }
  }

  onResetPassword(): void {
    this.loader = true;

    let code = this.passwordResetForm.value.codeR;
    let userName = this.forgotPasswordForm.value.emailF;
    let newPasswordR = this.passwordResetForm.value.newPasswordR;
    let againPasswordR = this.passwordResetForm.value.againPasswordR;

    if (this.showCompletePassword) {
      this.authService
        .completeNewPassword(
          this.email.value,
          this.password.value,
          newPasswordR
        )
        .then((res) => {
          if (res) {
            this.helperService.notify('SUCCESS', 'Password changed', 3000);
            this.changeMode(AuthPageMode.Login);
            this.showCompletePassword = false;
            location.reload();
          } else {
            this.helperService.notify('FAILED', 'Reset password failed', 3000);
            this.showErrorResetPassword = true;
          }
        });
    } else {
      this.authService
        .forgotPasswordSubmit(userName, code, newPasswordR)
        .then((res) => {
          this.loader = false;
          if (res == 'SUCCESS') {
            this.helperService.notify('SUCCESS', 'Password changed', 3000);
            this.changeMode(AuthPageMode.Login);
          } else {
            this.helperService.notify('FAILED', 'Reset password failed', 3000);
            this.showErrorResetPassword = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showErrorResetPassword = true;
          this.helperService.notify('FAILED', 'Reset password failed', 3000);
        });
    }
  }

  changeMode(mode: AuthPageMode): void {
    this.authPageMode = mode;
    this.passwordResetForm?.reset();
    this.showErrorResetPassword = false;
    this.resetValidations();
  }

  resetValidations(): void {
    this.isPasswordHasNumber =
      this.isPasswordHasSpecialCharacter =
      this.isPasswordLawerCase =
      this.isPasswordUpperCase =
      this.isPasswordUpTo8Characters =
      this.isMatchPasswords =
        false;
  }

  checkValidations(): void {
    this.isPasswordLawerCase = /[a-z]/.test(this.newPasswordR.value);
    this.isPasswordUpperCase = /[A-Z]/.test(this.newPasswordR.value);
    this.isPasswordHasNumber = /[0-9]/.test(this.newPasswordR.value);
    this.isPasswordHasSpecialCharacter =
      /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/.test(this.newPasswordR.value);
    this.isPasswordUpTo8Characters = this.newPasswordR.value.length > 7;
    this.isMatchPasswords =
      this.againPasswordR.value === this.newPasswordR.value;
  }

  resetPasswordResultValidation(): boolean {
    return (
      !this.isMatchPasswords ||
      !this.passwordResetForm.valid ||
      !this.isPasswordHasNumber ||
      !this.isPasswordHasSpecialCharacter ||
      !this.isPasswordLawerCase ||
      !this.isPasswordUpperCase ||
      !this.isPasswordUpTo8Characters
    );
  }

  onChangeVisibility(el: HTMLInputElement): void {
    this.passwordVisible = !this.passwordVisible;

    if (this.passwordVisible) {
      el.type = 'password';
    } else {
      el.type = 'text';
    }
  }
}
