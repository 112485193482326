import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { UserRoles } from '../models/enums';
import { removeStorageItemsByExclude } from '../helper-functions';
import { Subject } from 'rxjs';

export enum ValidationStatus {
  Invalid = 0,
  Valid = 1,
  Auth = 2,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public wrongCredentials = false;
  tokenHelper = new JwtHelperService();

  private _validationResult: ValidationStatus;
  public get validationResult(): ValidationStatus {
    return this._validationResult;
  }
  public set validationResult(v: ValidationStatus) {
    this._validationResult = v;
  }

  constructor() {
    this._validationResult = ValidationStatus.Auth;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async isTokenValid(): Promise<boolean> {
    try {
      // const user = await this.getCurrentUser();
      var isValid = (await Auth.currentSession()).isValid();
      return isValid;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async refreshToken(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      // console.log("idToken exp", idToken.getExpiration());
      //this.tokenHelper.decodeToken<any>(idToken.getJwtToken());
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        this.tockenRefreshed
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async getTokenExpiration(): Promise<Date> {
    try {
      const user = await this.getCurrentUser();
      var unix_timestamp = user
        .getSignInUserSession()
        .getIdToken()
        .getExpiration();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date(unix_timestamp * 1000);
      console.log('token expires at: ', date);
      return date;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getUserId(): Promise<string> {
    try {
      const attr = await this.getUserInfo();
      var idToken = attr.username;
      //console.log('attr', attr);
      return idToken;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getUserRoles(): Promise<any> {
    try {
      const user = await this.getCurrentUser();
      var payload = user.getSignInUserSession().getIdToken().payload[
        'cognito:groups'
      ];
      console.log('payload', payload);
      return payload;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  selectUserRoles(roles: Array<string>): number {
    //return UserRoles.TechAdmin;
    for (let index = 0; index < roles.length; index++) {
      if (roles[index] === 'accounting') {
        return UserRoles.Accounting;
      } else if (roles[index] === 'admin') {
        return UserRoles.Admin;
      } else if (roles[index] === 'techadmin') {
        return UserRoles.TechAdmin;
      } else if (roles[index] === 'companyadmin') {
        return UserRoles.CompanyAdmin;
      }
    }
    return 0;
  }

  async getIdToken(): Promise<CognitoIdToken> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      console.log('idToken', idToken);
      return idToken;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  
  async getCurrentUser(): Promise<CognitoUser> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('user', user);
      return user;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async getUserInfo(): Promise<any> {
    try {
      const res = await Auth.currentUserInfo();
      return res;
    } catch (err) {
      console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async signIn(userName, password) {
    try {
      // const res: CognitoUser = await Auth.signIn(userName, password);
      // return res;
      return Auth.signIn(userName, password)
        .then((user) => {
          if (!user) return -2;
          if (
            user.challengeName &&
            user.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            return -1;
          } else if (
            user &&
            user.attributes &&
            user.attributes['custom:company_id'] &&
            parseInt(user.attributes['custom:company_id']) > 0
          ) {
            // user is for BM
            return -2;
          } else {
            return 1;
          }
        })
        .catch((e) => {
          console.log(e);
          return -2;
        });
    } catch (err) {
      console.log('Current user info failed to fetch', err);
      return -2;
    }
  }
  async completeNewPassword(userName, code, password) {
    return Auth.signIn(userName, code)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          // we need this below attribute because lm pool requires it.
          const attributes = { name: 'New User' };
          return Auth.completeNewPassword(
            user, // the Cognito User Object
            password, // the new password
            attributes
          )
            .then((res) => {
              return true;
            })
            .catch((e) => {
              console.log(e);
              return false;
            });
        } else {
          // at this time the user is logged in if no MFA required

          console.log(user);
          return true;
          // other situations
        }
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  async changePassword(userName, oldPassword, newPassword) {
    try {
      const res = await Auth.changePassword(userName, oldPassword, newPassword);
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  async forgotPassword(userName): Promise<any> {
    try {
      const res = await Auth.forgotPassword(userName);
      return res;
    } catch (err) {
      return err;
    }
  }

  async forgotPasswordSubmit(userName, confirmationCode, password) {
    try {
      const res = await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        password
      );
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  public async getCognitoGroups(): Promise<Array<string>> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken()?.payload['cognito:groups'];

      return groups;
    } catch (error) {
      return null;
    }
  }

  async isAuthenticatedCognitoGroups(): Promise<boolean> {
    try {
      const groups = await this.getCognitoGroups();
      console.log('Groups : ', groups);
      if (groups == null)
        return true;
      if (groups?.length) {
        const adminGroups = groups.filter(
          (f) => f.toLowerCase() === 'techadmin'
        );

        if (adminGroups?.length) {
          return true;
        }
      }

      return false;
    } catch (e) {
      return false;
    }
  }

  async signOut() {
    try {
      await Auth.signOut({ global: true });
      //this.router.navigate(['/login'], { replaceUrl: true });
      // this.router.navigateByUrl('/login').then(() => {
      //   window.localStorage.removeItem('_fs_nav_name');
      //   window.location.reload();
      // });
    } catch (error) {
      console.error(error);
      // localStorage.clear();
      // //this.router.navigate(['/login'], { replaceUrl: true });
      // this.router.navigateByUrl('/login').then(() => {
      //   window.localStorage.removeItem('_fs_nav_name');
      //   window.location.reload();
      // });
    }
  }

  public async unautorized(excludeStorageKeys?: string[]): Promise<void> {
    try {
      await this.signOut();
    } catch (error) {
      console.log(error);
    } finally {
      this._validationResult = ValidationStatus.Invalid;
      removeStorageItemsByExclude(excludeStorageKeys);
      //window.location.href = '/login';
    }
  }

  private tockenRefreshed(e, r) {
    var idToken = r.getIdToken();
    // console.log("token refreshed", idToken.getExpiration());
    //this.tokenHelper.decodeToken<any>(idToken.getJwtToken());
    // console.log(e,r)
  }
}
