import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  token: any;

  constructor(public httpClient: HttpClient) {
    this.token = '';
  }

  public deleteCurrentSimId(simId: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.httpClient
      .delete<any>(environment.awsAPIURL, {
        headers: headers,
        body: { sim_id: simId },
      })
      .pipe((res) => {
        const objects = res || null;
        return objects;
      });
  }
}
