<div *ngIf="bcService.getBreadCrumb?.path?.length" class="bread-crumbs">
    <i [innerHtml]="bcService.getBreadCrumb.icon | safeHtml"></i>
    <h3>
        <!-- <button mat-button class="prm-btn back-btn" (click)="location.back()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button> -->
        <span *ngFor="let bc of bcService.getBreadCrumb.path" class="flex-start">
            <a *ngIf="bc.depth < bcService.getBreadCrumb.path.length" [routerLink]="'/' + bc.route">{{
                bc.title?.toUpperCase() }}
                <mat-icon>keyboard_arrow_right</mat-icon>
            </a>
            <span *ngIf="bc.depth === bcService.getBreadCrumb.path.length">{{ bc.title?.toUpperCase() }}</span>
        </span>
    </h3>
</div>