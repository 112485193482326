import { HttpParams } from '@angular/common/http';
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors,
  FormGroup,
} from '@angular/forms';
import { ValidationHelper } from './validation-helper';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { PasswordMaxLength } from './staticObjects';

export const $ = (selector: string) => {
  return document.querySelectorAll(selector);
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const intialValueInterval = async (
  callbackValue: () => {},
  delay: number = 100
): Promise<any> => {
  while (typeof callbackValue() === 'undefined' || callbackValue() === null)
    await sleep(delay);

  return callbackValue();
};

export const lowerCase = new RegExp(/[a-z]/);
export const upperCase = new RegExp(/[A-Z]/);
export const hasNumber = new RegExp(/[0-9]/);
export const hasSpecialCharacter = new RegExp(
  /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/
);
export const clearWhiteSpaces = new RegExp(/^\s+|\s+$|\s+(?=\s)/);
export const hasWhiteSpacees = new RegExp(/\s*\s$|\s{2,}/);

export const passwordValidation = (value: string): boolean =>
  value?.length &&
  new RegExp(lowerCase.source).test(value) &&
  new RegExp(upperCase.source).test(value) &&
  new RegExp(hasNumber.source).test(value) &&
  new RegExp(hasSpecialCharacter.source).test(value) &&
  value.length > 7 &&
  value.length < PasswordMaxLength;

export const buildQueryParams = (obj: Object): HttpParams => {
  let queryParams = new HttpParams();
  for (const key in obj) queryParams = queryParams.append(key, obj[key]);
  return queryParams;
};

export const dateToMedium = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const dateToShort = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });
};

export const convertUrlParamsToObject = (url: string): any => {
  if (url?.length) {
    url = url.replace('%20', '+');

    let segments = url.split('&');
    let result = {};

    segments.forEach((segment) => {
      let field = segment.split('=');
      result[field[0]] = field[1];
    });

    return result;
  }

  return null;
};

export const makeStrId = (length: number = 5) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const dateJSToInputDate = (date: Date | any): string => {
  if (!date) return '';

  if (typeof date === 'string') date = new Date(date);

  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);

  return `${date.getFullYear()}-${month}-${day}`;
};

export const inputDateToDateJS = (date: string): Date => {
  if (!date?.length) return null as any;

  let resultDate = new Date();

  if (date?.length) {
    let arr = date.split('-').map((m) => +m);

    resultDate.setFullYear(arr[0]);
    resultDate.setMonth(arr[1] - 1);
    resultDate.setDate(arr[2]);

    return resultDate;
  }

  return resultDate;
};

export const subStringFromEnd = (source: string, length: number) => {
  if (source?.length)
    return source.substring(source.length - length, source.length);
  else return '';
};

export const getUpperFirstChars = (
  source: string,
  seperator: string = ' '
): string => {
  let textArray = source.split(seperator);

  if (textArray?.length)
    return textArray.map((m) => m[0].toUpperCase()).join('');

  return source[0].toUpperCase();
};

export const distinctArray = <T>(source: T[]): T[] => {
  return source?.filter((item, index) => source.indexOf(item) === index);
};

export const distinctObjArray = <T>(source: T[], key: string = 'id'): T[] => {
  let objectsArr = new Array<T>();
  let keysArr = distinctArray<any>(source.map((m) => m[key]));

  if (keysArr?.length) {
    for (let i = 0; i < keysArr.length; i++) {
      objectsArr.push(source.find((f) => f[key] === keysArr[i]));
    }
  }

  return objectsArr;
};

export const sumNumbers = (
  source: number[],
  from: number = 0,
  to: number = source.length
): number => {
  let sum: number = 0;
  for (let i = from; i < to; i++) sum += source[i];
  return sum;
};

export const removeStorageItemsByExclude = (excludeKeys?: string[]): void => {
  let index = 0;

  while (true) {
    let key = window.localStorage.key(index);

    if (key?.length) {
      if (excludeKeys?.length) {
        let keyIndex = excludeKeys.findIndex((f) => f === key);

        if (keyIndex !== -1) {
          excludeKeys.slice(keyIndex, 1);
          index++;
          continue;
        }
      }

      window.localStorage.removeItem(key);
      index++;
    } else return;
  }
};

export const printRandomNumber = (
  digits: number = 100,
  inNegRange: boolean = false
): number => {
  let num = Math.round(Math.random() * digits);

  if (num === 0) return num;

  if (inNegRange) {
    let isPos = Math.round(Math.random() * 10) % 2 === 0;
    return isPos ? num : num * -1;
  }

  return num;
};

export const convertToInt = (value: string): number => {
  try {
    if (value?.length) {
      value = value.trim().replace(' ', '');

      if (value?.length) {
        let num = parseInt(value);

        if (!Number.isNaN(num) && typeof num === 'number') {
          return num;
        }
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
};

export const convertToFlatArray = <T>(source: Array<T>): Array<T> => {
  return source.reduce((accumulator, value) => accumulator.concat(value), []);
};

export const clearStringFromWhiteSpace = (value: string): string => {
  try {
    if (value?.length) return value.trim().replace(clearWhiteSpaces, '');
    return '';
  } catch (error) {
    return '';
  }
};

export const stringHasWhiteSpaces = (value: string): boolean => {
  return hasWhiteSpacees.test(value);
};

export const isTrueNumber = (value: number): boolean => {
  try {
    if (
      value === 0 ||
      (value !== undefined && value !== null && !Number.isNaN(value))
    )
      return true;

    return false;
  } catch (error) {
    return false;
  }
};

export const getElementIndexFromParent = (el: HTMLElement): number => {
  try {
    let parent = el.parentElement;
    return Array.from(
      parent.querySelectorAll(el.tagName.toLowerCase())
    )?.findIndex((f) => f === el);
  } catch (error) {
    return -1;
  }
};

export class CustomValidator {
  private static _isEmptyValue(control: AbstractControl): boolean {
    return !control.value || !control.value.length;
  }

  static isEmptyOrWhiteSpaces(control: AbstractControl): any {
    if (
      CustomValidator._isEmptyValue(control) ||
      !`${control.value}`?.trim()?.length
    )
      return { onlyWhitespacesValidator: true };

    return null;
  }

  static MoreThan2WhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = ValidationHelper.hasMoreThan2WP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static MoreThan2WhiteSpaceWithoutStartAndEnd(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = ValidationHelper.hasMoreThan2WP(`${control.value}`.trim());

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static StartEndWhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = ValidationHelper.hasStartEndWP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static AnyWhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = ValidationHelper.hasAnyWP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static phoneValidation(
    event: KeyboardEvent,
    phoneControl: FormControl<string>
  ): void {
    let el = <HTMLInputElement>event.target;

    if (
      el?.value.length &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      if (el.value.length === 3 || el.value.length === 7) {
        phoneControl.setValue(el.value + '-');
      }
    }
  }

  static LineChecker(charPerLine: number, maxEnters: number = 0): any {
    return (control: AbstractControl): any => {
      if (!control.value || !control.value.length) return null;

      let lines: Array<string> = control.value.split('\n');

      if (maxEnters > 0 && lines.length > maxEnters) {
        return { overMaxChar: true };
      }

      if (lines?.length) {
        for (let i = 0; i < lines.length; i++)
          if (lines[i].length > charPerLine) return { overMaxChar: true };
      }

      return null;
    };
  }

  static compareWith(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const controlToCompare = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (!controlToCompare || !matchingControl) {
        return null;
      }

      if (controlToCompare.value !== matchingControl.value) {
        matchingControl.setErrors({ notMatching: true });
        return { notMatching: true };
      } else {
        matchingControl.setErrors(null);
        return null;
      }
    };
  }

  static countryCodeNumber(code: CountryCode): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value?.length) {
        control.setErrors(null);
        return null;
      }
      
      const resultParse = parsePhoneNumber(
        control.value,
        code
      );
  
      if (resultParse?.isValid()) {
        control.setErrors(null);
        return null;
      }
  
      control.setErrors({ invalidPhoneNumber: true });
      return { invalidPhoneNumber: true };
    }
  }
}
