export enum UserRoles {
  Admin = 1,
  TechAdmin = 2,
  Accounting = 3,
  CompanyAdmin = 4,
}

export enum CompanyType {
  Residential = 0,
  Commercial = 1,
}

export enum ExpiringDateType {
  IsNULL = -1,
  Expired = 0,
  Just30DaysToExpired = 2,
  ALongTimeLeft = 1,
}

export enum UserAccountType {
  None = 0,
  BaymanagerAdmin = 1,
  CompeteAdmin = 2,
  BaymanagerCompeteAdmin = 3,
}
